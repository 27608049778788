import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import SEO from 'components/Seo';

const TermsPage = ({ location }) => (
  <Layout>
    <SEO
      keywords={['kopirun', 'delivery', 'food delivery', 'crowdsource', 'terms and conditions']}
      path={location.pathname}
      title="Terms & Conditions"
    />
    <div className="max-w-7xl mx-auto">
      <section className="flex-1 flex-col justify-center items-center mt-10 md:flex-row px-4 sm:px-6 lg:px-8">
        <div className="w-full">
          <h1 className="font-bold text-2xl no-underline mb-4">Terms & Conditions</h1>
          <p className="mb-4">
            Terms and Conditions These Terms of Service (&ldquo;Terms&rdquo;) govern your use of the
            websites, mobile applications and services (collectively, the &ldquo;Services&rdquo;)
            provided by KopiRun (as defined below). These Terms of Use and the KopiRun Policies (as
            defined below) constitute a legally binding agreement (&ldquo;Agreement&rdquo;) between
            you and KopiRun LLP (as defined below). For ease of reference, each of KopiRun LLP and
            its subsidiaries are referred to in these Terms as &ldquo;KopiRun&rdquo; or
            &ldquo;KOPIRUN&rdquo;.
          </p>

          <p className="mb-4">
            The Agreement applies to your use of the Service provided by KopiRun. If you do not
            agree to the Terms of Use, please do not use or continue using the Application (as
            defined below) or the Service.
          </p>

          <p className="mb-4">
            &ldquo;Application&rdquo; means the relevant mobile application(s) made available for
            download by KopiRun (or its licensors) to Users and Third-Party Providers respectively;
          </p>

          <p className="mb-4">
            &ldquo;KopiRun Content&rdquo; means all Contents that KopiRun makes available through
            the Site, Application, or Services, including any Content licensed from a third party,
            but excluding Member Content.
          </p>

          <p className="mb-4">
            &ldquo;Collective Content&rdquo; means Member Content and KopiRun content.
          </p>

          <p className="mb-4">
            &ldquo;Content&rdquo; means text, graphics, images, music, software (excluding the
            Application), audio, video, information or other materials.
          </p>

          <p className="mb-4">
            &ldquo;Requester&rdquo; means a Member who posts requests on the Site, Application or
            Services, including but not limited to requests for consumables, perishables, food and
            drink, pharmaceuticals or household goods, and asks the Offeror to deliver products in
            exchange of a potential charge or tips as agreed upon between respective requester and
            offeror. A requestor willingly enters into contracts, carries the burden of making
            requests, closes deals on his or her own discretion, and exempts KopiRun from any
            liability and excuses KopiRun for any mistakes, violations of trusts, and counterfeit
            actions happened between the time of deal beginning till its end made via the Site,
            Application, and Service.
          </p>

          <p className="mb-4">
            &ldquo;Offeror&rdquo; means a Member who creates an Offer or accepts a request and
            consents that upon making a deal with a requester, he or she is willing to carry a
            product to transport to the respective requester or the designated collection centre in
            exchange of a potential charge of any amount on voluntary basis, as agreed in their
            mutual deal decisions via the Site, Application and Services. An offeror willingly
            enters into contracts, carries the burden of transporting the products, closes deals on
            his or her own discretion, and exempts KopiRun from any liability, infringements on
            regulations such as declaration of Taxes and delivery of prohibited items. The offeror
            also excuses KopiRun for any mistakes, violations of trusts, and counterfeit actions
            happened between the time of deal beginning till its end made via the Site, Application,
            and Services.
          </p>

          <p className="mb-4">
            &ldquo;Deal&rdquo; means an agreement between a Requester and an Offeror regarding a
            requested item and a respective charge or tip (if applicable).
          </p>

          <p className="mb-4">
            &ldquo;Member&rdquo; means a person who completes KopiRun&rsquo;s account registration
            (signup or login) process, including, but not limited to Requestor and Offerors.
          </p>

          <p className="mb-4">
            &ldquo;Member Content&rdquo; means all Content that a Member posts, uploads, publishes,
            submits or transmits to be made available through the Site, Application or Services.
          </p>

          <p className="mb-4">
            &ldquo;User&rdquo; means any person who uses the Application, Platform and/or Software
            to search for and obtain the Solutions; and
          </p>

          <p className="mb-4">
            &ldquo;Personal Data&rdquo; is any information which can be used to identify you or from
            which you are identifiable. This includes but is not limited to your name, nationality,
            telephone number, bank and credit card details, personal interests, email address, your
            image, government-issued identification numbers, biometric data, race, date of birth,
            marital status, religion, health information, vehicle and insurance information;
          </p>

          <p className="mb-4">
            &ldquo;Platform&rdquo; means the relevant KopiRun technology platform, portal or website
            that, when used in conjunction with the Application, enables Users to request or access
            Solutions;
          </p>

          <p className="mb-4">
            &ldquo;Service&rdquo; means the linking of Users to Third Party Providers or other Users
            through the Application, Platform and/or Software;
          </p>

          <p className="mb-4">
            &ldquo;Software&rdquo; means any software associated with the Application which is
            supplied made available for download and installation by KopiRun;
          </p>

          <h1 className="font-bold text-lg mb-4"> 1. General</h1>

          <p className="mb-4">
            These Terms applies to all users of the Services, including without limitation users who
            are requestors, offerors, or contributors of content, information and other materials.
          </p>

          <p className="mb-4">
            By using the Services in any manner, including but not limited to visiting or browsing
            the Services, you agree to observe and be bound by these Terms and the additional terms
            and conditions and policies referenced herein and/or available by hyperlink.
          </p>

          <p className="mb-4">
            You acknowledge and agree that, by accessing or using the site, application or services
            or by downloading or posting any content from or on the site. You are indicating that
            you have read, and that you understand and agree to be bound by these terms, whether or
            not you have registered with the site and application. If you do not agree to these
            terms, then you have no right to access or use the site, application, services, or
            collective content.
          </p>

          <h1 className="font-bold text-lg mb-4">2. Modification</h1>

          <p className="mb-4">
            KopiRun reserves the right to change or modify these Terms at any time. You will be
            deemed to have agreed to the amended Terms by your continued use of the Services
            following the date on which the amended Terms are posted here. Such amendments shall be
            effective once they are posted on http://KopiRun.com or the Application. It is your
            responsibility to review the Terms of Use and KopiRun Policies regularly. Your continued
            use of the Service after any such amendments, whether or not reviewed by you, shall
            constitute your agreement to be bound by such amendments.
          </p>

          <p className="mb-4">
            KopiRun makes available a peer-to-peer platform with related technology for Requestors
            and Offerees or Offerors to connect and arrange for Deal between them. KopiRun is not an
            owner or seller or retailer or broker or commissioned agent of including, but not
            limited to, any product and services made and marketed by third party corporations,
            companies, individual seller or government service provider. KopiRun&rsquo;s
            responsibilities are limited to facilitating the availability of the Site.
          </p>

          <p className="mb-4">
            Please note that, as stated above, the site, application and services are intended to be
            used to facilitate the deal between requestor and offeror. KopiRun cannot and does not
            control the content contained in any listings and the condition, legality or suitability
            of any deal. KopiRun is not responsible for and disclaims any and all liability related
            to any and all listings and deals. Accordingly, any deals or transactions will be made
            at the member&rsquo;s own risk.
          </p>

          <h1 className="font-bold text-lg mb-4">3. Use of the Services</h1>

          <p className="mb-4">
            Subject to your compliance with these Terms, KopiRun grants you a limited,
            non-exclusive, revocable (with or without cause), non-transferable right and license to
            use the Services.
          </p>

          <p className="mb-4">
            You shall use the Services in accordance with these Terms and shall not:
          </p>

          <p className="mb-4">
            Upload any Content (as defined below) that violates or infringes another party&rsquo;s
            rights of publicity, privacy, copyright, trademark or any other intellectual property
            right.
          </p>

          <p className="mb-4">
            Copy, decompile, reverse engineer, disassemble, attempt to derive the source code of,
            decrypt, interfere with, or disrupt the integrity or the performance of the Services.
          </p>

          <p className="mb-4">
            Make any modification, adaptation, improvement, enhancement, translation or derivative
            work from the Services.
          </p>

          <p className="mb-4">
            Violate any applicable laws, rules or regulations in connection with your access or use
            of the Services.
          </p>

          <p className="mb-4">
            Use the Services in violation of or to circumvent any sanctions or embargo.
          </p>

          <p className="mb-4">
            Remove, alter or obscure any proprietary notice (including any notice of copyright and
            trademark) of KopiRun or its affiliates, partners, suppliers or licensors.
          </p>

          <p className="mb-4">
            Use the Services for any purpose for which it is not designed or intended.
          </p>

          <p className="mb-4">
            Use the Services to create or promote a product, service or software that is, directly
            or indirectly, competitive with or in any way a substitute for the Services or any
            services, product or software offered by KopiRun.
          </p>

          <p className="mb-4">
            Use any proprietary information or interfaces of KopiRun or any other intellectual
            property of KopiRun in the design, development, manufacture, licensing or distribution
            of any application, accessories or devices for use with the Services.
          </p>

          <p className="mb-4">
            Use the Services to send, post, or otherwise communicate any Content which is offensive,
            indecent, threatening, abusive, insulting, harassing, defamatory, libellous, deceptive,
            fraudulent, tortious, obscene, profane, invasive of another person&rsquo;s privacy, or
            racially, ethnically or otherwise objectionable.
          </p>

          <p className="mb-4">
            Use the Services to send automated, unsolicited or unauthorised messages, advertising or
            promotional material or any junk mail, spam or chain letters.
          </p>

          <p className="mb-4">
            Upload to, or transmit through the Services any data, file, software or link that
            contains or redirects to a virus, Trojan horse, worm or other harmful components.
          </p>

          <p className="mb-4">
            Interfere with or damage our Site, Application or Services, including, without
            limitation, through the use of viruses, cancel bots, Trojan horses, harmful code, flood
            pings, denial-of-service attacks, packet or IP spoofing, forged routing or electronic
            mail address information or similar methods or technology;
          </p>

          <p className="mb-4">
            Use any scraper, robot, bot, spider, crawler or any other automated device or means to
            access, acquire, copy or monitor any portion of the Services, or any data or content
            found or access through the Services.
          </p>

          <p className="mb-4">
            Collect any information in respect of other users without their consent.
          </p>

          <p className="mb-4">Commit any act to avoid paying any applicable fees and/or charges.</p>

          <p className="mb-4">
            Attempt to and/or engage in any activity or act that is intended to abuse, abuses or
            inappropriately manipulates any promotion, campaign and/or discount codes offered
            through the Services. Such act and activities include but are not limited to: creating
            fake or duplicate accounts; generating fake requests and offers; Authorise or encourage
            anyone to do any of the foregoing.
          </p>

          <p className="mb-4">
            Copy, store or otherwise access any information contained on the Site, Application,
            Services or Content for purposes not expressly permitted by these Terms;
          </p>

          <p className="mb-4">
            Use our Site, Application or Services to transmit, distribute, post or submit any
            information concerning any other person or entity, including without limitation,
            photographs of others without their permission, personal contact information or credit,
            debit, calling card or account numbers;
          </p>

          <p className="mb-4">
            KopiRun will have the right to investigate, ban and remove any Content and prosecute
            violations of any of the above to the fullest extent of the law. KopiRun may involve and
            cooperate with law enforcement authorities in prosecuting users who violate these Terms.
            You acknowledge that KopiRun has no obligation to monitor your access to or use of the
            Site, Application, Services or Collective Content or to review or edit any Member
            Content, but has the right to do so for the purpose of operating the Site, Application
            and Services, to ensure your compliance with these Terms, or to comply with applicable
            law or the order or requirement of a court, administrative agency or other governmental
            body. KopiRun reserves the right, at any time and without prior notice, to remove or
            disable access to any Collective Content that KopiRun, at its sole discretion, considers
            to be objectionable for any reason, in violation of these Terms or otherwise harmful to
            the Site, Application or Services.
          </p>

          <h1 className="font-bold text-lg mb-4">4. Account</h1>

          <p className="mb-4">
            You would need to have an account with KopiRun (&ldquo;Account&rdquo;) in order to use
            some parts of the Services. When you create an Account, you represent and warrant that:
          </p>

          <p className="mb-4">If you are an individual, you are at least 18 years of age.</p>

          <p className="mb-4">
            If you are representing a company, organisation or any other legal entity
            (&ldquo;Entity&rdquo;), you have authority to bind the Entity to these Terms.
          </p>

          <p className="mb-4">
            You are capable of entering into and performing legally binding contracts under
            applicable law.
          </p>

          <p className="mb-4">
            All information which you provide is accurate, up to date, truthful and complete. If you
            are under 18 years of age, you may only use the Services with the consent of and under
            the supervision of your parent or legal guardian who shall be responsible for all your
            activities.
          </p>

          <p className="mb-4">
            You are responsible for all activities and transactions under your Account. We will not
            be responsible in any way if your password and/or Account are misappropriated or used by
            a third party. You, therefore, agree to:
          </p>

          <p className="mb-4">Keep your password secure.</p>

          <p className="mb-4">Keep your account information up to date at all times.</p>

          <h1 className="font-bold text-lg mb-4">5. Member Content</h1>

          <p className="mb-4">
            We may, in our sole discretion, permit Members to post, upload, publish, submit or
            transmit Member Content. By making available any Member Content on or through the Site,
            Application and Services, you hereby grant to KopiRun a worldwide, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free license, with the right to
            sublicense, to use, view, copy, adapt, modify, distribute, license, sell, transfer,
            publicly display, publicly perform, transmit, stream, broadcast, access, view, and
            otherwise exploit such Member Content on, through, or by means of the Site, Application
            and Services. KopiRun does not claim any ownership rights in any such Member Content and
            nothing in these Terms will be deemed to restrict any rights that you may have to use
            and exploit any such Member Content.
          </p>

          <p className="mb-4">
            You acknowledge and agree that you are solely responsible for all Member Content that
            you make available through the Site, Application and Services. Accordingly, you
            represent and warrant that: (i) you either are the sole and exclusive owner of all
            Member Content that you make available through the Site, Application and Services or you
            have all rights, licenses, consents and releases that are necessary to grant to KopiRun
            the rights in such Member Content, as contemplated under these Terms; and (ii) neither
            the Member Content nor your posting, uploading, publication, submission or transmittal
            of the Member Content or KopiRun&rsquo;s use of the Member Content (or any portion
            thereof) on, through or by means of the Site, Application and the Services will
            infringe, misappropriate or violate a third party&rsquo;s patent, copyright, trademark,
            trade secret, moral rights or other proprietary or intellectual property rights, or
            rights of publicity or privacy, or result in the violation of any applicable law or
            regulation.
          </p>

          <p className="mb-4">
            KopiRun may also block Content and the delivery of a communication (including, without
            limitation, feedback, requests, offers, messages and/or chats) to or from the Services
            as part of efforts to protect the Services or users, or to otherwise enforce these
            Terms.
          </p>

          <h1 className="font-bold text-lg mb-4">6. URL and hyperlinks to 3rd party websites</h1>

          <p className="mb-4">
            The Site, Application and Services may contain links to third-party websites or
            resources. You acknowledge and agree that KopiRun is not responsible or liable for: (i)
            the availability or accuracy of such websites or resources; or (ii) the content,
            products, or services on or available from such websites or resources. Links to such
            websites or resources do not imply any endorsement by KopiRun of such websites or
            resources or the content, products, or services available from such websites or
            resources. You acknowledge sole responsibility for and assume all risk arising from your
            use of any such websites or resources or the Content, products or services on or
            available from such websites or resources.
          </p>

          <h1 className="font-bold text-lg mb-4">7. Taxes</h1>

          <p className="mb-4">
            7.1. You agree that this Agreement is subject to all prevailing statutory taxes, duties,
            fees, charges and/or costs, however denominated, as may be applicable from time to time.
            You shall comply with all applicable laws and take all steps required to enable, assist
            and/or defend KopiRun to claim or verify any input tax credit, set off, rebate or refund
            in respect of any taxes paid or payable in connection with the Service.
          </p>

          <p className="mb-4">
            7.2. If you are a Third-Party Provider, you are accountable for paying any tax and
            statutory contributions due in respect of sums payable to you under or in connection
            with this Agreement.
          </p>

          <h1 className="font-bold text-lg mb-4">8. Disputes with users</h1>

          <p className="mb-4">
            If you are in dispute with a user of our Services, you are encouraged to contact that
            user to resolve the situation amicably. If the dispute is notified to KopiRun, KopiRun
            is under no obligation to resolve the same. You release KopiRun from any claims, demands
            and damage arising out of your disputes with users of our Services.
          </p>

          <p className="mb-4">
            KopiRun will not be a party to disputes or negotiations of disputes between you and
            users or third-party providers including merchants, advertisers and/or sponsors. KopiRun
            cannot and will not play any role in managing payments between you and other users,
            third party providers, including merchants, advertisers and/or sponsors. Responsibility
            for the decisions you make regarding services and products offered via the service, the
            software and/or the application (with all its implications) rests solely with and on
            you. You expressly waive and release KopiRun from any and all liability, claims, causes
            of action, or damages arising from your use of the service, the software and/or the
            application, or in any way related to the users and third parties including merchants,
            advertisers and/or sponsors introduced to you by the service, the software and/or the
            application.
          </p>

          <h1 className="font-bold text-lg mb-4">9. Intellectual Property</h1>

          <p className="mb-4">
            You acknowledge and agree that the Services and all copyright, patents, trademarks,
            trade secrets and other intellectual property rights associated therewith are, and shall
            remain, the property of KopiRun. Furthermore, you acknowledge and agree that the source
            and object code of the Services and the format, directories, queries, algorithms,
            structure and organization of the Services are the intellectual property and proprietary
            and confidential information of KopiRun. You are not granted any intellectual property
            rights in and to the Services not expressly granted in these Terms and such rights are
            hereby reserved and retained by KopiRun.
          </p>

          <p className="mb-4">
            The Services may utilise or include third party software that is subject to open source
            and third-party licence terms (&ldquo;Third Party Software&rdquo;). You acknowledge and
            agree that your right to use such Third Party Software as part of the Services is
            subject to and governed by the terms and conditions of the open source and third party
            licenses applicable to such Third Party Software, including, without limitation, any
            applicable acknowledgements, licence terms and disclaimers contained therein. In the
            event of a conflict between these Terms and the terms of such open source or third-party
            licences, the terms of the open source or third-party licences shall prevail with regard
            to your use of the relevant third-party software. In no event shall the Services or
            components thereof be deemed to be open source or publicly available software.
          </p>

          <p className="mb-4">
            You are not authorised by KopiRun to use KopiRun&rsquo;s trademarks in any advertising,
            publicity or in any other commercial manner without the prior written consent of
            KopiRun, which may be withheld for any or no reason.
          </p>

          <h1 className="font-bold text-lg mb-4">10. Reporting Unauthorised Content</h1>

          <p className="mb-4">
            KopiRun respects the intellectual property rights of others and we expect the same of
            our users. If you believe that there has been unauthorised use of your intellectual
            property rights by a user, please follow the steps provided here to report the same to
            us.
          </p>

          <p className="mb-4">
            KopiRun reserves the right to suspend or terminate your account and/or your access to
            the Services if there are repeat complaints against you of intellectual property
            infringement.
          </p>

          <h1 className="font-bold text-lg mb-4">11. Suspension and Termination</h1>

          <p className="mb-4">
            KopiRun reserves the right to, at its sole discretion, and without liability:
          </p>

          <p className="mb-4">
            Suspend or terminate your Account and/or your access to the Services at any time, for
            any breach of these Terms, our guidelines, any additional terms and conditions and
            policies. Suspend or terminate your Account and/or your access to the Services at any
            time, for any reason, and without advance notice.
          </p>

          <p className="mb-4">
            Change, modify, suspend or discontinue, whether temporarily or permanently, the whole or
            any part of the Services at any time, for any reason, and without advance notice. You
            acknowledge and agree that if you deactivate your Account or if we suspend or terminate
            your Account, you will lose any information associated with your Account, including
            Content. It is your responsibility to back up the Content which you upload to the
            Services.
          </p>

          <p className="mb-4">
            Upon termination, all licenses and rights granted to you in these Terms will immediately
            cease.
          </p>

          <h1 className="font-bold text-lg mb-4">12. No Warranty</h1>

          <p className="mb-4">
            You acknowledge and agree that the Services are provided on an &ldquo;as is&rdquo; and
            &ldquo;as available&rdquo; basis, and that your use of or reliance upon the Services and
            any content, products or services accessed or obtained thereby is at your sole risk and
            discretion.
          </p>

          <p className="mb-4">
            KopiRun does not represent or warrant that: (a) the use of the Services will be secure,
            timely, uninterrupted or error free or that they will be compatible or operate in
            combination with any other hardware, software, system or data; (b) the Services will
            meet your requirements or expectations; (c) errors or defects in the Services will be
            corrected; or (d) the Services and KopiRun&rsquo;s servers are free of viruses or other
            harmful components.
          </p>

          <p className="mb-4">
            All conditions, representations and warranties, whether express, implied, statutory or
            otherwise, including, without limitation, any implied warranties of merchantability,
            fitness for a particular purpose, and non-infringement, are hereby excluded and
            disclaimed to the fullest extent permitted under the law.
          </p>

          <p className="mb-4">
            The Services may be subject to limitations, delays, and other problems inherent in the
            use of the Internet and electronic communications (including problems inherent to the
            computer or electronic device you use). KopiRun is not responsible for any delays,
            delivery failures, damages, or losses resulting from such problems.
          </p>

          <p className="mb-4">
            No advice or information, whether oral or written, obtained by you from KopiRun or from
            the Services shall create any representation, warranty or guarantee. Furthermore, you
            acknowledge that KopiRun has no obligation to support or maintain the Services.
          </p>

          <p className="mb-4">
            KopiRun disclaims any and all liability or responsibility in relation to the content
            made available through the Services, including but not limited to the Content uploaded
            by users or the third-party content and services. KopiRun is not responsible or liable
            in any manner for the third-party content and services associated with or utilized in
            connection with the Services, including the failure of such third-party content and
            services.
          </p>

          <p className="mb-4">
            In addition, the Services may promote, advertise or sponsor functions, events, offers,
            competition or other activities which may be conducted online or offline and which may
            be conducted by third parties. These activities may be subjected to separate terms and
            conditions; your participation in such activities is solely at your digression and risk.
            KopiRun does not accept any responsibility in connection with your participation in
            activities conducted by any other party.
          </p>

          <h1 className="font-bold text-lg mb-4">13. Limitation of Liability</h1>

          <p className="mb-4">
            YOU ACKNOWLEDGE AND AGREE THAT YOUR ONLY RIGHT WITH RESPECT TO ANY PROBLEMS OR
            DISSATISFACTION WITH THE SERVICES IS TO REQUEST FOR TERMINATION OF YOUR ACCOUNT AND/OR
            DISCONTINUE ANY USE OF THE SERVICES.
          </p>

          <p className="mb-4">
            IN NO EVENT SHALL KOPIRUN OR ITS SUBSIDIARIES, AFFILIATES, PARTNERS, SUPPLIERS OR
            LICENSORS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY
            DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS OR USE OF OR INABILITY TO
            ACCESS OR USE THE SERVICES OR ANY THIRD PARTY CONTENT AND SERVICES, OR ARISING OUT OF OR
            IN CONNECTION WITH YOUR CONDUCT OR THE CONDUCT OF OTHER USERS IN CONNECTION WITH THE USE
            OF THE SERVICES INCLUDING WITHOUT LIMITATION DEATH, BODILY INJURY, EMOTIONAL DISTRESS
            AND/OR OTHER DAMAGES RESULTING FROM COMMUNICATIONS OR MEETINGS WITH OTHER USERS OR
            PERSONS YOU MEET THROUGH THE SERVICES, WHETHER OR NOT THE DAMAGES WERE FORESEEABLE AND
            WHETHER OR NOT KOPIRUN WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>

          <p className="mb-4">
            IF YOU CHOOSE TO USE THE SITE, APPLICATION, SERVICES, YOU DO SO AT YOUR SOLE RISK. YOU
            ACKNOWLEDGE AND AGREE THAT KOPIRUN DOES NOT HAVE AN OBLIGATION TO CONDUCT BACKGROUND
            CHECKS ON ANY MEMBER, INCLUDING, BUT NOT LIMITED TO, REQUESTOR AND OFFEROR, BUT MAY
            CONDUCT SUCH BACKGROUND CHECKS IN ITS SOLE DISCRETION. THE SITE, APPLICATION, SERVICES
            AND COLLECTIVE CONTENT ARE PROVIDED &ldquo;AS IS&rdquo;, WITHOUT WARRANTY OF ANY KIND,
            EITHER EXPRESS OR IMPLIED.
          </p>

          <p className="mb-4">
            KOPIRUN MAKES NO WARRANTY THAT THE SITE, APPLICATION, SERVICES, COLLECTIVE CONTENT,
            INCLUDING, BUT NOT LIMITED TO, THE REQUESTS AND OFFERS WILL MEET YOUR REQUIREMENTS OR BE
            AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. KOPIRUN MAKES NO WARRANTY
            REGARDING THE QUALITY OF ANY LISTINGS, THE SERVICES OR COLLECTIVE CONTENT OR THE
            ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY COLLECTIVE
            CONTENT OBTAINED THROUGH THE SITE, APPLICATION, SERVICES. NO ADVICE OR INFORMATION,
            WHETHER ORAL OR WRITTEN, OBTAINED FROM KOPIRUN OR THROUGH THE SITE, APPLICATION,
            SERVICES OR COLLECTIVE CONTENT, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
          </p>

          <p className="mb-4">
            YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH OTHER
            USERS OF THE SITE, APPLICATION OR SERVICES AND WITH OTHER PERSONS WITH WHOM YOU
            COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SITE, APPLICATION OR SERVICES,
            INCLUDING, BUT NOT LIMITED TO, ANY REQUESTOR, OFFEROR OR USER.
          </p>

          <p className="mb-4">
            YOU UNDERSTAND THAT KOPIRUN DOES NOT MAKE ANY ATTEMPT TO VERIFY THE STATEMENTS OF USERS
            OF THE SITE, APPLICATION OR SERVICES.
          </p>

          <p className="mb-4">
            KOPIRUN MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE SITE,
            APPLICATION OR SERVICES OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE USERS OF THE
            SITE, APPLICATION OR SERVICES.
          </p>

          <p className="mb-4">
            KOPIRUN DIRECTORS, OFFICERS, EMPLOYEES, AND AFFILIATES FROM KOPIRUN, NOT BE LIABLE FOR
            ANY DIRECT OR INDIRECT DAMAGES, WHETHER INCIDENTAL NATURE, PUNISHMENT, SPECIAL, OR
            CONSEQUENTIAL DAMAGES ARISING FROM ANY USE OF THE SITE BY YOU OR FOR ANY OTHER CLAIM
            THAT ASSOCIATED WITH THE SITE FROM YOUR USAGE, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS
            OR INCOMPLETENESS IN ANY CONTENT, OR LOSS OR DAMAGE OF ANY KIND ARISING AS RESULT OF USE
            OF THE CONTENT OR ANY PRODUCT THAT POSTED, DISTRIBUTED, OR OTHERWISE MADE AVAILABLE
            THROUGH THE SITE, EVEN IF THE POSSIBILITY OF SUCH DAMAGES AND CLAIMS HAVE BEEN ADVISED.
          </p>

          <p className="mb-4">
            KOPIRUN WILL COMPLY TO FAIR BUSINESS TO PROTECT THE INFORMATION THAT YOU PROVIDED TO THE
            SITE, BUT YOU ALSO AGREE THAT YOUR SUBMISSION OF SUCH INFORMATION IS AT YOUR SOLE RISK,
            AND HEREBY DISCLAIM KOPIRUN ANY AND ALL LIABILITY TO YOU FOR DAMAGES OR LIABILITY
            WHATSOEVER ASSOCIATED TO THE INFORMATION IN ANY FORM AND HOW.
          </p>

          <p className="mb-4">
            KOPIRUN DOES NOT EXPRESS AND MAKES NO WARRANTY THAT KOPIRUN WILL BE FREE FROM LOSS,
            CORRUPTION, ATTACK, VIRUSES, INTERFERENCE, HACKING, OR OTHER SECURITY INTRUSION, AND
            KOPIRUN DISCLAIM ANY LIABILITY RELATING THERETO. YOU WILL BE SOLELY RESPONSIBLE FOR
            BACKING UP YOUR OWN SYSTEM.
          </p>

          <h1 className="font-bold text-lg mb-4">14. Indemnity</h1>

          <p className="mb-4">
            You will indemnify and hold harmless KopiRun, its subsidiaries, affiliates, partners,
            suppliers, licensors, shareholders, officers, employees, independent contractors and
            agents and all successors and/or assigns from and against any and all actions, claims,
            proceedings, damages, losses, costs and expenses resulting from: (a) your use of the
            Services; (b) your Content; (c) any use of your Account; (d) your breach of these Terms;
            (e) your breach of any statutory requirement, duty or law; or (f) your violation of any
            rights of another person or entity.
          </p>

          <h1 className="font-bold text-lg mb-4">15. Governing Law</h1>

          <p className="mb-4">
            These Terms of Use will be governed by and construed in accordance with the laws of
            Singapore, and the courts of Singapore will have non-exclusive jurisdiction over any
            claim or dispute arising under or in connection with these Terms of Use.
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

TermsPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default TermsPage;
